@font-face {
  font-family: 'NHaasGrotesk';
  src: url('./NHaasGroteskTXPro-65Md.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Spezia-Serif-C';
  src: url('./Spezia\ Serif\ Condensed\ Web\ C-normal-400-100.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'NHaas-65Md';
  src: url('./NHaasGroteskTXPro-65Md.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'NHaas-55Rg';
  src: url('./NHaasGroteskTXPro-55Rg.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
}
