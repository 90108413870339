.section-principles {
    width: 100%;
    line-height: 1.35;
    background-color: #eeffe3;
    padding: 70px 60px 80px;

    @media(max-width: 992px) {
        width: 100%;
        height: unset;
        background-color: #eeffe3;
        padding: 20px 40px 80px;
        margin-top: 30px;
    }

    .title-wrapper {
        width: 100%;
        background-color: #eeffe3;
        margin-bottom: 50px;

        @media(max-width: 992px) {
            margin-bottom: 25px;
        }

        .title {
            text-align: center;
            font-size: 49px;
            font-family: "Spezia-Serif-C";
            color: #004850;

            @media(max-width: 992px) {
                font-size: 34px;
            }
        }
    }

    .principle-list {
        display: flex;
        justify-content: space-between;
        gap: 30px;

        @media(max-width: 992px) {
            display: flex;
            flex-direction: column;
        }

        .principle-list-item {
            display: flex;
            flex-direction: column;
            max-width: 316px;

            @media(max-width: 992px) {
                max-width: unset!important;
            }

            .title {
                font-size: 21px;
                font-weight: bold;
                text-align: center;
                color: #3BE089;
                font-family: 'NHaas-65Md';
            }

            .details {
                font-size: 21px;
                font-family: 'NHaas-55Rg';
                color: #004850;
                line-height: 1.35;
                text-align: left;

                @media(max-width: 992px) {
                    font-size: 16px;
                }
            }
        }
    }
}
