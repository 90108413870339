.page-services {
    display: flex;
    flex-direction: column;

    .main-bg-image {
        width: 100%;
        height: 634px;
        object-fit: cover;

        @media(max-width: 575px) {
            height: 400px;
        }

        @media(max-width: 1200px) {
            height: 500px;
        }
    }

    .summary {
        width: 100%;
        display: flex;
        background: #004850;
        padding: 80px 75px;
        gap: 160px;

        @media(max-width: 1200px) {
            gap: 80px;
        }

        @media(max-width: 992px) {
            flex-direction: column;
            align-items: center;
            padding: 100px 40px;
            gap: 50px;
        }

        .title {
            flex-shrink: 0;
            width: 40%;
            min-width: 520px;
            font-family: 'Spezia-Serif-C';
            font-size: 50px;
            line-height: 1.2;
            color: #efffe3;

            @media(max-width: 1200px) {
                font-size: 40px;
                min-width: 400px;
            }

            @media(max-width: 992px) {
                font-size: 34px;
                min-width: unset;
                width: 100%;
            }

            @media(max-width: 768px) {
                font-size: 34px;
                min-width: 300px;
            }
        }

        .details {
            max-width: 568px;
            font-family: 'NHaas-55Rg';
            font-size: 21px;
            color: #efffe3;
            margin-top: 10px;

            @media(max-width: 992px) {
                font-size: 16px;
                margin-top: 0;
                max-width: unset;
            }
        }
    }

    .content-wrapper {
        background: #efffe3;
        padding-top: 57px;
        padding-bottom: 80px;

        @media(max-width: 992px) {
            padding-bottom: 60px;
        }

        .section-directions {
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 70px;
            margin-bottom: 80px;
            padding: 0 40px;

            @media(max-width: 992px) {
                gap: 50px;
                margin-bottom: 100px;
            }

            .title {
                font-family: 'Spezia-Serif-C';
                font-size: 41px;
                color: #004850;

                @media(max-width: 992px) {
                    font-size: 34px;
                    text-align: center;
                }
            }

            .direction-list {
                display: flex;
                width: 100%;
                max-width: 1194px;

                @media(max-width: 768px) {
                    flex-direction: column;
                    gap: 20px;
                }

                .direction-list-item {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    width: 33%;
                    gap: 35px;

                    @media(max-width: 992px) {
                        gap: 25px;
                        width: unset;
                    }

                    .direction-icon {
                        width: 57px;
                        height: 57px;

                        @media(max-width: 768px) {
                            width: 40px;
                            height: 40px;
                        }
                    }

                    .direction-name {
                        font-family: 'NHaas-55Rg';
                        font-size: 17px;
                        color: #004850;

                        @media(max-width: 768px) {
                            max-width: 200px;
                            text-align: center;
                        }
                    }
                }
            }
        }

        .section-with-image {
            display: flex;
            align-items: center;
            margin-bottom: 80px;

            @media(max-width: 992px) {
                flex-direction: column-reverse !important;
                margin-bottom: 80px;
                gap: 70px;
            }

            &.reverse {
                flex-direction: row-reverse;
            }

            .section-text-wrapper {
                flex: 1;
                display: flex;
                flex-direction: column;
                gap: 45px;
                padding: 0 60px;

                @media(max-width: 992px) {
                    padding: 0 40px;
                }

                .title {
                    font-family: 'Spezia-Serif-C';
                    font-size: 41px;
                    line-height: 1.2;
                    color: #004850;

                    @media(max-width: 992px) {
                        font-size: 34px;
                        text-align: center;
                    }

                    @media(max-width: 768px) {
                        text-align: left;
                    }
                }

                .details {
                    font-family: 'NHaas-55Rg';
                    font-size: 17px;
                    color: #004850;
                }
            }

            .section-image {
                flex: 1;
                width: 50%;
                height: 521px;
                object-fit: cover;

                @media(max-width: 992px) {
                    width: 100%;
                    height: 450px;
                    flex: unset;
                }
            }
        }

        .section-with-list {
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 52px;
            padding: 0 60px;
            margin-bottom: 80px;

            @media(max-width: 992px) {
                padding: 0 40px;
                margin-bottom: 60px;
            }

            .title {
                font-family: 'Spezia-Serif-C';
                font-size: 41px;
                line-height: 1.2;
                color: #004850;
                text-align: left;
                width: 100%;

                @media(max-width: 992px) {
                    font-size: 34px;
                    text-align: center;
                    max-width: unset;
                }

                @media(max-width: 768px) {
                    text-align: left;
                }
            }

            .details {
                font-family: 'NHaas-55Rg';
                font-size: 17px;
                width: 100%;
                color: #004850;
                text-align: left;

                @media(max-width: 992px) {
                    text-align: center;
                    max-width: unset;
                }

                @media(max-width: 768px) {
                    text-align: left;
                }
            }

            .section-content-list {
                width: 100%;
                display: flex;
                gap: 30px;

                @media(max-width: 992px) {
                    flex-direction: column;
                }

                .section-content-list-item {
                    flex: 1;
                    display: flex;
                    flex-direction: column;
                    gap: 20px;

                    .subtitle,
                    .description {
                        font-size: 17px;
                        line-height: 1.5;
                        color: #004850;
                        max-width: 416px;

                        @media(max-width: 992px) {
                            font-size: 16px;
                        }
                    }

                    .subtitle {
                        font-family: 'NHaas-65Md';
                        font-weight: bold;
                    }

                    .description {
                        font-family: 'NHaas-55Rg';
                    }
                }
            }
        }

        .section-contacts {
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 52px;

            @media(max-width: 992px) {
                padding: 0 40px;
            }

            .title {
                font-family: 'Spezia-Serif-C';
                font-size: 41px;
                line-height: 1.2;
                color: #004850;
                max-width: 50%;

                @media(max-width: 992px) {
                    font-size: 34px;
                    text-align: center;
                    max-width: unset;
                }

                @media(max-width: 768px) {
                    text-align: left;
                }
            }

            .details {
                font-family: 'NHaas-55Rg';
                font-size: 21px;
                line-height: 1.35;
                color: #004850;
                max-width: 610px;
                text-align: center;
            }

            .btn {
                padding: 12px 24px;
                border-radius: 0;
                font-size: 16px;
                font-family: 'NHaas-65Md';
            }
        }
    }
}