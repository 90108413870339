.page-blog {
    display: flex;
    flex-direction: column;
    width: 100%;

    &.list {
        background: #efffe3;

        .summary {
            background: #efffe3;
            flex-direction: column;
            gap: 30px;
            align-items: center;
            padding-bottom: 90px;

            .title {
                color: #004850;
            }

            .details {
                font-family: "NHaas-55Rg";
                font-size: 21px;
                color: #004850;
            }
        }
    }

    .main-bg-image {
        width: 100%;
        height: 634px;
        object-fit: cover;

        @media(max-width: 575px) {
            height: 400px;
        }

        @media(max-width: 1200px) {
            height: 500px;
        }
    }

    .summary {
        width: 100%;
        display: flex;
        background: #004850;
        padding: 80px 75px;

        @media(max-width: 992px) {
            flex-direction: column;
            align-items: center;
            padding: 50px 40px;
        }

        .title {
            flex-shrink: 0;
            width: 100%;
            min-width: 520px;
            font-family: 'Spezia-Serif-C';
            font-size: 50px;
            line-height: 1.2;
            color: #efffe3;
            text-align: center;

            @media(max-width: 1200px) {
                font-size: 40px;
                min-width: 400px;
            }

            @media(max-width: 992px) {
                font-size: 34px;
                min-width: unset;
                width: 100%;
                text-align: center
            }

            @media(max-width: 768px) {
                font-size: 34px;
                min-width: 300px;
            }
        }
    }

    .content-wrapper {
        display: flex;
        flex-direction: column;
        align-items: center;
        background: #efffe3;
        padding: 60px;
        gap: 20px;

        @media(max-width: 992px) {
            padding: 50px 40px;
        }

        .author {
            width: 100%;
            font-family: 'NHaas-65Md';
            font-size: 18px;
            line-height: 1.2;
            color: #004850;
            margin-bottom: 40px;
        }

        .blog-section {
            width: 100%;
            display: flex;
            flex-direction: column;
            gap: 5px;

            &.quotation {
                .section-content {
                    text-align: center;
                }
            }

            .title {
                width: 100%;
                font-family: 'NHaas-55Rg';
                font-size: 30px;
                line-height: 1.2;
                color: #004850;
                margin-top: 15px;
            }

            .section-content {
                width: 100%;
                font-family: 'NHaas-55Rg';
                font-size: 21px;
                color: #004850;
                margin-top: 10px;
                text-align: left;
                margin-bottom: 0.5rem;
    
                @media(max-width: 992px) {
                    font-size: 16px;
                    margin-top: 0;
                    max-width: unset;
                }
            }

            .list {
                display: flex;
                flex-direction: column;

                .list-item {
                    width: 100%;
                    font-family: 'NHaas-55Rg';
                    font-size: 21px;
                    color: #004850;
                    margin-top: 10px;
                    text-align: left;

                    @media(max-width: 992px) {
                        font-size: 16px;
                        margin-top: 0;
                        max-width: unset;
                    }
                }
            }

            .complex-list {
                display: flex;
                flex-direction: column;
                gap: 20px;
                margin-top: 20px;

                .section {
                    color: #004850;

                    div {
                        font-family: 'NHaas-55Rg';
                        font-size: 21px;
                        margin-bottom: 0.5rem;
                    }
                }
            }

            .image-list {
                display: flex;
                flex-wrap: wrap;
                gap: 50px;
                margin: 30px 0;

                .image {
                    width: 100%;
                    height: 600px;
                    object-fit: contain;

                    @media(max-width: 992px) {
                        height: unset;
                    }
                }
            }
        }
    }

    .blog-list-wrapper {
        width: 100%;
        max-width: 1718px;
        display: flex;
        gap: 50px;
        margin: 0 auto 70px;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
        padding: 0 50px;

        .blog-list-item {
            display: flex;
            flex-direction: column;
            width: calc(33% - 30px);
            height: 630px;
            flex-shrink: 0;

            .article-wallpaper {
                width: 100%;
                height: 371px;
                object-fit: cover;
            }

            .blog-article-summary {
                padding: 20px;
                background: rgba(229, 251, 214, 1);
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                gap: 20px;
                color: #004850;
                flex-shrink: 0;
                height: 250px;

                .blog-article-main-details {
                    display: flex;
                    flex-direction: column;
                    gap: 20px;
                }

                .title {
                    font-size: 22px;
                }

                @media (max-width: 1350px) {
                    height: 220px;
                }

                @media (max-width: 1160px) {
                    height: 250px;
                }

                @media (max-width: 900px) {
                    height: unset;
                }
            }

            .product-link {
                margin-top: 10px;
                align-self: flex-start;
            }

            @media (max-width: 1350px) {
                width: calc(50% - 30px);
                height: 630px;
            }

            @media (max-width: 1160px) {
                height: 660px;
            }

            @media (max-width: 900px) {
                width: 100%;
                max-width: 350px;
                height: unset;
            }
        }

        @media (max-width: 1350px) {
            gap: 30px;
        }

        @media (max-width: 900px) {
            flex-direction: column;
        }
    }
}