.page-home {
    background-color: #eeffe3;
    display: flex;
    flex-direction: column;
    align-items: center;

    .section-principles {
        padding-top: 40px;
    }
}

.home-section-1 {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-right: 0vw;
    padding-bottom: 80px;
    background-color: #eeffe3;
}

.home-section-3-variant {
    display: flex;
    width: 50%;
    flex-shrink: 0;
    height: 100%;
    background-color: #eeffe3;
    gap: 5%;
}

.home-section-3-padding {
    width: 50%;
    flex-shrink: 0;
    padding: 0 60px;
}

.home-section-3-cubes-image {
    height: 700px;
    width: 100%;
}

.text-box-variant {
    width: 100%;
    color: #004850;
    height: 100%;
    font-size: 49px;
    text-align: left;
    margin-left: 5%;
    font-family: 'Spezia-Serif-C';
}

.small-text-variant {
    width: 80%;
    color: #004850;
    font-size: 21px;
    text-align: left;
    margin-left: 5%;
    margin-top: 5%;
    align-self: flex-start;
    font-family: 'NHaas-55Rg';
}

.home-section-1-variant {
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content: center;
    align-items: center;
    background-color: #eeffe3;
    gap: 20px;
    padding: 0 60px;
}

.crosshair {
    color: #eec833;
    height: 101px;
    width: 101px;
    margin-bottom: 40px;
}

.stephen {
    height: 116px;
    width: 116px;
}

.stephen-text {
    width: 100%;
    max-width: 1000px;
    color: #004850;
    font-size: 21px;
    line-height: 130%;
    text-align: center;
    font-family: 'NHaas-55Rg';
}

.contact-info-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    align-items: center;
    margin-bottom: 20px;

    @media(max-width: 992px) {
        margin-top: 0;
    }
}

.contact-info-wrapper div {
    font-family: 'NHaas-55Rg';
    font-size: 16px;

    @media(max-width: 992px) {
        text-align: center;
    }
}

.contact-info-wrapper div.name {
    font-family: 'NHaas-65Md';
    font-weight: bold;
}

.stephen-small-text {
    color: #004850;
    margin-top: 50px;
    font-size: 14px;
    width: 115px;
    padding-bottom: 50px;
    border-bottom: 2px solid #004850;
}

.home-section-2 {
    width: 100%;
    color: #004850;
    background-color: #eeffe3;
    text-align: center;
    font-family: 'Spezia-Serif-C';
    padding: 100px 60px;
}

.home-section-2-text-header {
    text-align: center;
    width: 100%;
    line-height: 1.2;
    font-size: 34px;
}

.home-section-1-image-container {
    width: 50%;
    height: 700px;
}

.home-section-2-small-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 25px;
    text-align: left;
    margin-top: 40px;
}

.home-3x1-text {
    font-size: 21px;
    max-width: 416px;
    font-family: 'NHaas-55Rg';
    line-height: 1.35;
}

.home-photo {
    width: 100%;
    height: 100%;
}

.home-section-3 {
    width: 100%;
    height: 700px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    margin-right: 0vw;
}

.home-section-3-top-text {
    width: 100%;
    max-width: 566px;
    color: #004850;
    height: 100%;
    font-size: 49px;
    line-height: 1.2;
    text-align: left;
    font-family: 'Spezia-Serif-C';
}

.home-section-3-bottom-text {
    width: 100%;
    max-width: 580px;
    color: #004850;
    font-size: 22px;
    text-align: left;
    margin-top: 20px;
    margin-bottom: 30px;
    align-self: flex-start;
    font-family: 'NHaas-55Rg';
}

.home-section-4 {
    width: 100%;
    color: #004850;
    background-color: #eeffe3;
    font-size: 49px;
    font-family: 'Spezia-Serif-C';
    padding: 100px 60px;
}

.home-section-4-header {
    font-size: 33px;
    text-align: center;
    width: 100%;
    line-height: 1.2;

    @media(max-width: 992px) {
        font-size: 34px;
        text-align: left;
    }
}

.home-section-4-first-text {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 25px;
    text-align: left;
    margin-top: 50px;
}

.product-link {
    font-size: 14px;
    color: #004850;
    padding-bottom: 15px;
    border-bottom: 2px solid #004850;
    margin-top: 30px;
    text-decoration: none;
    font-weight: bold;
    font-family: 'NHaas-65Md';
    text-transform: uppercase;
    width: fit-content;

    img {
        margin-left: 8px;
    }
}

.home-section-4-column {
    display: flex;
    flex-direction: column;

    @media(max-width: 992px) {
        flex-direction: row;
        align-items: center;
        gap: 15px;
    }
}

.home-section-4-second-text {
    max-width: 410px;
    font-size: 21px;
    line-height: 1.35;
    margin-top: 40px;
    font-family: 'NHaas-55Rg';
}

.home-section-5 {
    width: 100%;
    height: 700px;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.home-section-5-left {
    width: 50%;
    height: 700px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.home-section-5-left-inner {
    padding: 60px;
}

.home-section-5-header {
    width: 100%;
    color: #004850;
    background-color: #eeffe3;
    font-size: 49px;
    text-align: left;
    font-family: 'Spezia-Serif-C';
    line-height: 1.2;
    margin-top: 5%;
}

.home-section-5-second-text {
    font-size: 21px;
    max-width: 580px;
    padding-top: 40px;
    color: #004850;
    font-family: 'NHaas-55Rg';
}

.home-section-5-photo {
    width: 50%;
    height: 100%;
}

.home-section-6-principles-list {
    display: flex;
    justify-content: space-between;
    gap: 30px;
}

.home-section-7 {
    width: 100%;
    min-height: 780px;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.home-section-7-left {
    width: 100%;
    height: 100%;
}

.home-section-7-photo {
    width: 100%;
    height: 100%;
}

.home-section-7-box {
    width: 100%;
    color: #004850;
    background-color: #eeffe3;
    font-size: 49px;
    font-family: 'Spezia-Serif-C';
    min-height: 780px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 50px 0 20px;
}

.home-section-7-subtext-outer-box {
    display: flex;
    flex-direction: column;
    padding: 40px 10%;
    gap: 25px;
    text-align: left;
}

.home-section-7-header {
    padding: 0 10%;
    line-height: 1.2;
}

.home-section-7-sub-header {
    font-size: 26px;
    padding: 0 10%;
    margin-top: 40px;
    font-family: 'NHaas-55Rg';
}

.home-section-7-subtext-box {
    display: flex;
    align-items: center;
    gap: 10px;
    font-size: 21px;
}

.home-section-7-subtext-box p {
    margin-bottom: 0;
    font-family: 'NHaas-55Rg';
}

.home-section-7-leaf {
    height: 18px;
    width: 22px;
}

.home-section-8 {
    width: 100%;
    height: 700px;
    display: flex;
    flex-direction: row;
}

.home-section-8-right {
    width: 50%;
    height: 100%;
}

.home-section-8-image {
    height: 100%;
    width: 100%;
}

.home-section-8-left {
    width: 50%;
    text-align: left;
    background: #004850;
}

.home-section-8-left-inner-container {
    padding: 5%;
    margin-top: 5%;
}

.home-section-8-heading {
    color: #e4ffd1;
    font-family: 'Spezia-Serif-C';
    font-size: 49px;
    line-height: 1.2;
    margin-bottom: 40px;
}

.home-section-8-text {
    color: #e4ffd1;
    color: #e4ffd1;
    font-family: 'NHaas-55Rg';
    font-size: 21px;
    line-height: 1.35;
    width: 100%;
    max-width: 486px;
}

@media (max-width: 992px) {
    .product-link {
        align-self: center;
    }

    .content {
        width: 100%;
        height: fit-content;
        flex-direction: column;
        align-items: center;
        justify-content: flex-end;
    }

    .home-section-1 {
        width: 100%;
        height: fit-content;
        display: flex;
        flex-direction: column-reverse;
        align-items: center;
        justify-content: flex-end;
        margin-right: 0vw;
        padding-bottom: 50px;
    }

    .home-section-1-variant {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        padding: 20px 40px 50px;
        height: unset;
    }

    .row {
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 100%;
        justify-content: center;
    }

    .stephen {
        height: 116px;
        width: 116px;
        margin-left: 0;
    }

    .stephen-text {
        width: 100%;
        color: #004850;
        font-size: 18px;
        text-align: left;
        margin-left: 0;
        margin-top: 0;
        align-self: flex-start;
        font-family: 'NHaas-55Rg';
    }

    .stephen-small-text {
        width: 100%;
        padding-bottom: 20px;
        text-align: center;
    }

    .home-section-1-image-container {
        width: 100%;
        height: 390px;
    }

    .home-section-2 {
        width: 100%;
        color: #004850;
        background-color: #eeffe3;
        font-size: 25px;
        text-align: center;
        font-family: 'Spezia-Serif-C';
        padding-top: 2vh;
        padding: 50px 40px;
    }

    .home-section-2-text-header {
        text-align: left;
        width: 100%;
        font-size: 32px;
    }

    .home-section-2-small-header {
        display: flex;
        flex-direction: column;
        padding: 0;
        gap: 2%;
        text-align: left;
    }

    .home-3x1-text {
        font-size: 15px;
        padding-top: 1%;
    }

    .home-section-3 {
        width: 100%;
        height: unset;
        display: flex;
        flex-direction: column-reverse;
        align-items: center;
        justify-content: flex-start;
        margin-right: 0vw;
    }

    .home-section-3-variant {
        display: flex;
        width: 100%;
        height: unset;
        background-color: #eeffe3;
        gap: 20px;
    }

    .home-section-3-cubes-image {
        height: 100%;
        width: 100%;
    }

    .home-section-3-padding {
        padding: 50px 40px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        height: unset;
        gap: 25px;
        width: 100%;
    }

    .crosshair {
        margin-bottom: 0;
    }

    .home-section-3-top-text {
        width: 100%;
        color: #004850;
        height: fit-content;
        text-align: left;
        margin-left: 0;
        font-family: 'Spezia-Serif-C';
        font-size: 34px;
    }

    .home-section-3-bottom-text {
        width: 100%;
        color: #004850;
        font-size: 17px;
        text-align: left;
        margin-left: 0;
        margin-top: 0;
        margin-bottom: 10px;
        align-self: flex-start;
        font-family: 'NHaas-55Rg';
    }

    .home-section-4 {
        width: 100%;
        color: #004850;
        background-color: #eeffe3;
        font-size: 25px;
        text-align: center;
        font-family: 'Spezia-Serif-C';
        padding: 50px 40px;
    }

    .home-section-4-first-text {
        flex-direction: column;
        margin-bottom: 20px;
    }

    .home-section-5 {
        width: 100%;
        display: flex;
        flex-direction: column-reverse;
        align-items: center;
        height: 100%;
    }

    .home-section-5-left {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 50px 40px;
    }

    .home-section-5-left-inner {
        display: flex;
        gap: 25px;
        padding: 0;
        flex-direction: column;
        align-items: center;
    }

    .home-section-5-header {
        width: 100%;
        color: #004850;
        background-color: #eeffe3;
        font-size: 34px;
        text-align: left;
        font-family: 'Spezia-Serif-C';
        margin-top: 0;
    }

    .home-section-5-second-text {
        font-size: 18px;
        padding-top: 0;
        color: #004850;
    }

    .home-section-5-photo {
        width: 100%;
        height: 100%;
    }

    .home-section-7 {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        height: 100%;
    }

    .home-section-7-left {
        width: 100%;
        height: 100%;
    }

    .home-section-7-photo {
        width: 100%;
        height: 100%;
    }

    .home-section-7-box {
        width: 100%;
        display: flex;
        color: #004850;
        background-color: #eeffe3;
        font-size: 25px;
        justify-content: center;
        flex-direction: column;
        padding: 50px 40px;
    }

    .home-section-7-sub-text {
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    .home-section-7-subtext-outer-box {
        display: flex;
        flex-direction: column;
        gap: 30px;
        padding: 0px;
        text-align: left;
        height: fit-content;
    }

    .home-section-7-header {
        padding: 0;
        font-size: 34px;
    }

    .home-section-7-sub-header {
        font-size: 18px;
        padding: 0;
    }

    .home-section-7-subtext-box {
        display: flex;
        font-size: 15px;
    }

    .home-section-7-leaf {
        height: 18px;
        width: 22px;
    }

    .home-section-8 {
        width: 100%;
        height: unset;
        display: flex;
        flex-direction: column-reverse;
    }

    .home-section-8-right {
        width: 100%;
        height: 100%;
    }

    .home-section-8-image {
        height: 100%;
        width: 100%;
    }

    .home-section-8-left {
        width: 100%;
        text-align: left;
        padding: 50px 40px;
    }

    .home-section-8-left-inner-container {
        padding: 0;
        margin-top: 0;
    }

    .home-section-8-heading {
        color: #e4ffd1;
        font-family: 'Spezia-Serif-C';
        font-size: 34px;
    }

    .home-section-8-text {
        color: #e4ffd1;
        font-size: 18px;
        width: 100%;
    }
}