.header {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #004850;
    padding: 0 48px;

    .content-wrapper {
        display: flex;
        flex-direction: row;
        width: 100%;
        height: 195px;
        background-color: #004850;

        @media(max-width: 992px) {
            height: 120px;
        }

        .content {
            display: flex;
            align-items: center;
            justify-content: space-between;
            gap: 40px;
            width: 100%;
            height: 195px;

            @media(max-width: 992px) {
                height: 120px;
            }

            .logo {
                width: 240px;
                height: 48px;
            }

            .navbar {
                display: flex;
                gap: 10px 30px;
                justify-content: flex-start;
                align-items: center;

                @media(max-width: 992px) {
                    display: none;
                }

                a {
                    text-decoration: none;

                    &:hover {
                        &.navbar-item {
                            color: #3be089;
                        }
                    }
                }

                .navbar-item {
                    font-family: "NHaasGrotesk";
                    color: #e4ffd1;
                    text-transform: uppercase;
                    font-size: 14px;
                    line-height: 24px;

                    &.mobile {
                        @media(min-width: 993px) {
                            display: none;
                        }
                    }
                }

                .dropdown {
                    line-height: 18px;

                    .dropdown-menu {
                        background-color: #efffe3;

                        a {
                            color: #004850;
                            font-size: 16px;
                            font-family: 'NHaas-55Rg';
                            line-height: 24px;

                            &:hover {
                                background-color: #efffe3;
                                color: #3be089;
                            }
                        }
                    }
                }
            }

            .btn-menu {
                padding: 0;
                outline: none;
                border: 0;
            }

            .menu-icon {
                width: 40px;
                height: 31px;

                @media(min-width: 992px) {
                    display: none;
                }
            }

            .btn-contact {
                display: flex;
                justify-content: center;
                align-items: center;
                border-radius: 0;
                font-family: "NHaasGrotesk";
                border: 1px solid #3be089;
                color: #3be089;
                font-size: 15px;
                text-align: center;
                text-transform: uppercase;
                padding: 10px 20px;

                .text {
                    line-height: 1;
                }

                &:hover {
                    background: none;

                    .text {
                        color: #3be089;
                    }
                }

                @media(max-width: 992px) {
                    display: none;
                }
            }
        }
    }
}

.offcanvas-header {
    width: 300px !important;
    background-color: #efffe3;

    .offcanvas-body {
        width: 100%;
    }

    .navbar {
        display: flex;
        flex-direction: column;
        gap: 30px;

        a {
            text-decoration: none;

            &:hover {
                .navbar-item {
                    color: #3be089;
                }
            }
        }

        .navbar-item {
            font-family: "NHaasGrotesk";
            color: #004850;
            text-transform: uppercase;
            font-size: 13px;
        }
    }
}