.page-grant-classifier {
    display: flex;
    flex-direction: column;

    .main-bg-image {
        width: 100%;
        height: 634px;
        object-fit: cover;

        @media(max-width: 575px) {
            height: 400px;
        }

        @media(max-width: 1200px) {
            height: 500px;
        }
    }

    .summary {
        width: 100%;
        display: flex;
        align-items: center;
        background: #eec833;
        padding: 80px 75px;
        gap: 120px;

        @media(max-width: 1200px) {
            gap: 80px;
        }

        @media(max-width: 992px) {
            flex-direction: column;
            align-items: center;
            padding: 80px 40px;
            gap: 50px;
        }

        .summary-title-wrapper {
            display: flex;
            align-items: center;
            gap: 60px;

            @media(max-width: 992px) {
                flex-direction: column;
                align-items: center;
            }
        }

        .summary-image {
            width: 135px;
            height: 135px;
        }

        .title {
            flex-shrink: 1;
            max-width: 500px;
            font-family: 'Spezia-Serif-C';
            font-size: 50px;
            line-height: 1.2;
            color: #004850;

            @media(max-width: 1200px) {
                font-size: 40px;
                min-width: 300px;
            }

            @media(max-width: 992px) {
                font-size: 34px;
                min-width: unset;
                width: 100%;
                text-align: center;
            }

            @media(max-width: 768px) {
                font-size: 34px;
                min-width: 300px;
            }
        }

        .details {
            max-width: 568px;
            font-family: 'NHaas-55Rg';
            font-size: 21px;
            color: #004850;
            margin-top: 10px;

            @media(max-width: 1200px) {
                margin-top: 0;
            }

            @media(max-width: 992px) {
                font-size: 16px;
                margin-top: 0;
                max-width: unset;
                text-align: center;
            }
        }
    }

    .content-wrapper {
        position: relative;
        background: #efffe3;
        padding-bottom: 80px;

        @media(max-width: 992px) {
            padding-bottom: 47px;
        }

        .crosshair-image {
            position: absolute;
            left: 0;
            top: 43%;
            width: calc(50% - 100px);
            transform: translateY(-50%);

            @media(max-width: 992px) {
                display: none;
            }
        }

        .section-with-image {
            display: flex;
            align-items: center;
            margin-bottom: 120px;

            @media(max-width: 992px) {
                flex-direction: column-reverse !important;
                margin-bottom: 60px;
                gap: 60px;
            }

            &.reverse {
                flex-direction: row-reverse;
            }

            .section-text-wrapper {
                flex: 1;
                display: flex;
                flex-direction: column;
                gap: 45px;
                padding: 0 60px;

                @media(max-width: 992px) {
                    padding: 0 40px;
                    gap: 25px;
                }

                .title {
                    font-family: 'Spezia-Serif-C';
                    font-size: 41px;
                    line-height: 1.2;
                    color: #004850;

                    @media(max-width: 992px) {
                        font-size: 34px;
                        text-align: center;
                    }

                    @media(max-width: 768px) {
                        text-align: left;
                    }
                }

                .details {
                    font-family: 'NHaas-55Rg';
                    font-size: 21px;
                    color: #004850;

                    @media(max-width: 992px) {
                        font-size: 15px;
                        text-align: center;
                    }
                }

                .author {
                    font-family: 'NHaas-65Md';
                    font-size: 18px;
                    color: #004850;

                    @media(max-width: 992px) {
                        font-size: 15px;
                        text-align: left;
                    }
                }

                .product-link {
                    margin-top: 10px;
                    align-self: flex-start;
                }
            }

            .section-image {
                flex: 1;
                width: 50%;
                height: 704px;
                object-fit: cover;

                @media(max-width: 992px) {
                    width: 100%;
                    height: 450px;
                    flex: unset;
                }
            }

            &.small {
                .section-text-wrapper {
                    padding: 0 30px;

                    @media(max-width: 992px) {
                        padding: 0 40px;
                    }
                }

                .title {
                    font-size: 29px;
                }
            }
        }

        .section-right-float {
            display: flex;
            align-items: center;
            justify-content: flex-end;
            margin-bottom: 80px;

            @media(max-width: 992px) {
                flex-direction: column-reverse !important;
                margin-bottom: 40px;
            }

            .section-text-wrapper {
                width: 50%;
                display: flex;
                flex-direction: column;
                gap: 45px;
                padding: 0 60px 0 0;

                @media(max-width: 992px) {
                    padding: 0 40px;
                    width: 100%;
                }

                .title {
                    font-family: 'Spezia-Serif-C';
                    font-size: 41px;
                    line-height: 1.2;
                    color: #004850;

                    @media(max-width: 992px) {
                        font-size: 34px;
                        text-align: center;
                    }

                    @media(max-width: 768px) {
                        text-align: left;
                    }
                }

                .details {
                    font-family: 'NHaas-55Rg';
                    font-size: 18px;
                    color: #004850;

                    @media(max-width: 992px) {
                        font-size: 15px;
                        text-align: center;
                    }
                }

                .point-list {
                    margin-top: -20px;
                    padding-left: 0px;
                    list-style-type: none;

                    li {
                        position: relative;
                        display: flex;
                        align-items: center;
                        height: 70px;
                        font-family: 'NHaas-65Md';
                        font-size: 17px;
                        line-height: 1.35;
                        color: #004850;
                        padding-left: 30px;

                        @media(max-width: 992px) {
                            padding: 30px 0 0 30px;
                            height: unset;
                        }

                        &:before {
                            position: absolute;
                            content: '';
                            display: inline-block;
                            height: 20px;
                            width: 20px;
                            background-image: url("../../images/grant-classifier/list-image.svg");
                            background-size: contain;
                            background-repeat: no-repeat;
                            left: 0;
                          }
                    }
                }

                .benefit-list{
                    display: flex;
                    flex-wrap: wrap;
                    gap: 20px;

                    .benefit-item {
                        width: calc(50% - 20px);
                        display: flex;
                        flex-direction: column;
                        gap: 25px;

                        @media(max-width: 992px) {
                            width: 100%;
                        }

                        .title {
                            font-size: 17px;
                            font-weight: 700;
                            line-height: 1.35;
                            font-family: 'NHaas-55Rg';
                            color: #eec833;
                        }

                        .description {
                            font-size: 17px;
                            line-height: 1.35;
                            font-family: 'NHaas-55Rg';
                            color: #004850;
                        }
                    }
                }
            }
        }

        .section-partner {
            display: flex;
            flex-direction: column;
            align-items: center;
            padding: 80px 60px 60px;

            @media(max-width: 992px) {
                padding: 50px 40px 50px;
            }

            .description {
                font-family: 'NHaas-55Rg';
                font-size: 24px;
                line-height: 1.48;
                text-align: center;
                max-width: 1200px;
                margin-bottom: 35px;
                color: #004850;

                @media(max-width: 992px) {
                    font-size: 18px;
                }
            }

            .partner-avatar {
                width: 96px;
                height: 96px;
                border-radius: 50%;
                overflow: hidden;
                margin-bottom: 20px;
                object-fit: cover;
            }

            .contact-info-wrapper {
                display: flex;
                flex-direction: column;
                align-items: center;

                div {
                    font-family: 'NHaas-55Rg';
                    font-size: 16px;
                    color: #004850;

                    &.name {
                        font-family: 'NHaas-65Md';
                        font-weight: bold;
                        color: #eec833;
                    }
                }
            }
        }

        .section-elements {
            display: flex;
            flex-direction: column;
            align-items: center;
            padding: 80px 60px 80px;
            background-color: #004850;
            gap: 60px;

            @media(max-width: 992px) {
                padding: 50px 40px;
            }

            .title {
                font-family: 'Spezia-Serif-C';
                font-size: 48px;
                line-height: 1.2;
                color: #efffe3;

                @media(max-width: 992px) {
                    font-size: 34px;
                    text-align: center;
                }

                @media(max-width: 768px) {
                    text-align: left;
                }
            }

            .section-list {
                display: flex;
                flex-wrap: wrap;
                justify-content: center;
                gap: 25px;

                @media(max-width: 992px) {
                    gap: 35px;
                }

                .section-list-item {
                    flex-shrink: 0;
                    display: flex;
                    flex-wrap: wrap;
                    flex-direction: column;
                    align-items: center;
                    gap: 40px;
                    width: calc(25% - 20px);
                    min-width: 305px;

                    @media(max-width: 992px) {
                        gap: 15px;
                        width: 100%;
                    }

                    .name {
                        font-size: 17px;
                        font-weight: 500;
                        color: #3be089;
                        text-align: center;
                    }

                    .description {
                        font-size: 16px;
                        line-height: 1.5;
                        text-align: left;
                        font-family: 'NHaas-55Rg';
                        color: #efffe3;
                    }
                }
            }
        }

        .section-contacts {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 80px 60px 0;

            @media(max-width: 992px) {
                flex-direction: column;
                padding: 70px 40px 0;
                gap: 30px;
            }

            .info-wrapper {
                display: flex;
                flex-direction: column;
                gap: 30px;
            }

            .title {
                font-family: 'Spezia-Serif-C';
                font-size: 41px;
                line-height: 1.2;
                color: #004850;
                max-width: 50%;

                @media(max-width: 992px) {
                    font-size: 34px;
                    text-align: center;
                    max-width: unset;
                }
            }

            .details {
                font-family: 'NHaas-55Rg';
                font-size: 18px;
                line-height: 1.5;
                color: #004850;
            }

            .btn {
                background: #004850;
                border: 0;
                padding: 12px 24px;
                border-radius: 0;
                font-size: 16px;
                font-family: 'NHaas-65Md';
                color: #e4ffd1;
            }
        }
    }
}