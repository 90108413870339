.page-contact {
    display: flex;
    flex-direction: column;
    width: 100%;

    .main-bg-image {
        width: 100%;
        height: 634px;
        object-fit: cover;

        @media(max-width: 575px) {
            height: 400px;
        }

        @media(max-width: 1200px) {
            height: 500px;
        }
    }

    .summary {
        width: 100%;
        display: flex;
        text-align: center;
        background: #e5fbd6;
        padding: 95px 60px;
        gap: 30px;

        @media(max-width: 1200px) {
            gap: 80px;
        }

        @media(max-width: 992px) {
            flex-direction: column;
            align-items: center;
            padding: 100px 40px;
            gap: 50px;
        }

        .title {
            width: 50%;
            font-family: 'Spezia-Serif-C';
            font-size: 50px;
            line-height: 1.2;
            color: #004850;
            margin: 0;

            @media(max-width: 1200px) {
                font-size: 40px;
                min-width: 400px;
            }

            @media(max-width: 992px) {
                font-size: 34px;
                min-width: unset;
                width: 100%;
                text-align: center;
            }

            @media(max-width: 768px) {
                font-size: 34px;
            }
        }

        .details {
            display: flex;
            align-items: center;
            width: 50%;
            font-family: 'NHaas-55Rg';
            font-size: 21px;
            color: #004850;
            margin-top: 8px;
            max-width: 568px;
            text-align: left;

            @media(max-width: 992px) {
                width: 100%;
                font-size: 16px;
                justify-content: center;
            }
        }
    }

    .content-wrapper {
        display: flex;
        align-items: center;
        background: #efffe3;
        padding: 150px 60px 80px;
        gap: 60px;

        @media(max-width: 992px) {
            flex-direction: column-reverse;
            padding: 80px 60px 80px;
        }

        .contact-form-wrapper {
            width: 50%;
            display: flex;
            flex-direction: column;
            gap: 10px;

            @media(max-width: 992px) {
                width: 100%;
            }

            .contact-form {
                display: flex;
                flex-direction: column;
                gap: 35px;

                .contact-input {
                    height: 58px;
                    border-radius: 0;
                    background: #e5fbd6;
                    border: 0;
                    color: #004850;

                    &::placeholder {
                        font-size: 17px;
                        color: #00485040;
                    }
                }

                .contact-textarea {
                    height: 347px;
                    border-radius: 0;
                    background: #e5fbd6;
                    border: 0;
                    color: #004850;

                    &::placeholder {
                        font-size: 17px;
                        color: #00485040;
                    }
                }
            }

            .btn-submit {
                height: 58px;
                background: #e5fbd6;
                color: #004850;
                font-size: 13px;
                font-weight: 700;
                font-family: 'NHaas-55Rg';
                text-transform: uppercase;
                border: 0;

                &:disabled {
                    opacity: 0.5;
                }
            }

            .info-message {
                display: none;
                font-size: 13px;

                &.show {
                    display: block;
                }
            }
        }

        .contact-info-wrapper {
            width: 50%;
            display: flex;
            flex-direction: column;
            gap: 80px;

            @media(max-width: 992px) {
                width: 100%;
            }

            .title {
                font-family: 'Spezia-Serif-C';
                font-size: 34px;
                line-height: 1.2;
                color: #004850;
            }

            .contact-info {
                display: flex;
                gap: 20px;
                max-width: 536px;

                .contact-info-section {
                    width: 50%;
                    display: flex;
                    flex-direction: column;
                    gap: 7px;

                    .contact-info-item {
                        font-family: 'NHaas-55Rg';
                        font-size: 17px;
                        color: #004850;
                    }
                }
            }
        }
    }
}
