.page-grant-impact-reporting {
    display: flex;
    flex-direction: column;

    .main-bg-image {
        width: 100%;
        height: 634px;
        object-fit: cover;

        @media(max-width: 575px) {
            height: 400px;
        }

        @media(max-width: 1200px) {
            height: 500px;
        }
    }

    .summary {
        width: 100%;
        display: flex;
        background: #3be089;
        padding: 120px 75px 70px;
        gap: 120px;

        @media(max-width: 1200px) {
            gap: 80px;
        }

        @media(max-width: 992px) {
            flex-direction: column;
            align-items: center;
            padding: 80px 40px;
            gap: 50px;
        }

        .summary-title-wrapper {
            display: flex;
            gap: 60px;

            @media(max-width: 992px) {
                flex-direction: column;
                align-items: center;
            }
        }

        .summary-image {
            width: 135px;
            height: 135px;
        }

        .title {
            flex-shrink: 1;
            max-width: 500px;
            font-family: 'Spezia-Serif-C';
            font-size: 50px;
            line-height: 1.2;
            color: #004850;

            @media(max-width: 1200px) {
                font-size: 40px;
                min-width: 300px;
            }

            @media(max-width: 992px) {
                font-size: 34px;
                min-width: unset;
                width: 100%;
                text-align: center;
            }

            @media(max-width: 768px) {
                font-size: 34px;
                min-width: 300px;
            }
        }

        .details {
            max-width: 568px;
            font-family: 'NHaas-55Rg';
            font-size: 21px;
            color: #004850;
            margin-top: 10px;

            @media(max-width: 1200px) {
                margin-top: 0;
            }

            @media(max-width: 992px) {
                font-size: 16px;
                margin-top: 0;
                max-width: unset;
                text-align: center;
            }
        }
    }

    .content-wrapper {
        background: #efffe3;
        padding-bottom: 80px;

        @media(max-width: 992px) {
            padding-bottom: 47px;
        }

        .section-with-image {
            display: flex;
            align-items: center;
            margin-bottom: 120px;

            @media(max-width: 992px) {
                flex-direction: column-reverse !important;
                margin-bottom: 80px;
                gap: 100px;
            }

            &.reverse {
                flex-direction: row-reverse;
            }

            .section-text-wrapper {
                flex: 1;
                display: flex;
                flex-direction: column;
                gap: 35px;
                padding: 0 60px;

                @media(max-width: 992px) {
                    gap: 25px;
                    padding: 0 40px;
                }

                .title {
                    font-family: 'Spezia-Serif-C';
                    font-size: 41px;
                    line-height: 1.2;
                    color: #004850;
                    max-width: 655px;

                    @media(max-width: 992px) {
                        font-size: 34px;
                        text-align: center;
                        margin-bottom: 15px;
                    }

                    @media(max-width: 768px) {
                        text-align: left;
                    }
                }

                .details {
                    font-family: 'NHaas-55Rg';
                    font-size: 21px;
                    color: #004850;
                }

                .author {
                    font-family: 'NHaas-65Md';
                    font-size: 18px;
                    color: #004850;

                    @media(max-width: 992px) {
                        font-size: 15px;
                        text-align: left;
                    }
                }

                .product-link {
                    margin-top: 10px;
                    align-self: flex-start;
                }

                .date {
                    font-family: 'NHaas-55Rg';
                    font-size: 13px;
                    line-height: 1.5;
                    color: #004850;
                }
            }

            .section-image {
                flex: 1;
                width: 50%;
                height: 704px;
                object-fit: cover;

                @media(max-width: 992px) {
                    width: 100%;
                    height: 450px;
                    flex: unset;
                }
            }
        }

        .section-decisions {
            display: flex;
            align-items: center;
            padding: 50px 60px;

            @media(max-width: 992px) {
                flex-direction: column !important;
                gap: 50px;
                padding: 40px 40px;
            }

            .section-image-wrapper {
                flex: 1;
                width: 50%;

                .section-image {
                    flex: 1;
                    width: 80%;
                    object-fit: cover;
    
                    @media(max-width: 992px) {
                        width: 70%;
                        flex: unset;
                        align-self: center;
                    }
                }

                @media(max-width: 992px) {
                    width: 100%;
                    display: flex;
                    justify-content: center;
                }
            }

            .section-text-wrapper {
                flex: 1;
                display: flex;
                flex-direction: column;
                gap: 45px;
                padding: 0 60px;

                @media(max-width: 992px) {
                    padding: 0;
                }

                .title {
                    font-family: 'Spezia-Serif-C';
                    font-size: 41px;
                    line-height: 1.2;
                    color: #004850;
                    max-width: 655px;

                    @media(max-width: 992px) {
                        font-size: 31px;
                        text-align: center;
                    }

                    @media(max-width: 768px) {
                        text-align: left;
                    }
                }

                .section-list {
                    display: flex;
                    flex-wrap: wrap;
                    gap: 100px 30px;

                    .section-list-item {
                        display: flex;
                        flex-direction: column;
                        gap: 20px;
                        width: calc(50% - 20px);

                        .title {
                            font-size: 17px;
                            line-height: 1.35;
                            color: #3be089;
                            font-family: 'NHaas-65Md';
                        }

                        .details {
                            font-size: 14px;
                            line-height: 1.35;
                            color: #004850;
                            font-family: 'NHaas-55Rg';
                        }
                    }
                }
            }
        }

        .section-partner {
            display: flex;
            flex-direction: column;
            align-items: center;
            padding: 80px 60px 60px;
            background-color: #004850;

            @media(max-width: 992px) {
                padding: 70px 40px 60px;
            }

            .description {
                font-family: 'NHaas-55Rg';
                font-size: 21px;
                line-height: 1.3;
                text-align: center;
                max-width: 1200px;
                margin-bottom: 25px;
                color: #efffe3;
                margin-top: 10px;

                @media(max-width: 992px) {
                    font-size: 18px;
                }
            }

            .partner-avatar {
                width: 116px;
                height: 116px;
                border-radius: 50%;
                overflow: hidden;
                margin-bottom: 20px;
                object-fit: cover;
            }

            .contact-info-wrapper {
                display: flex;
                flex-direction: column;
                align-items: center;

                div {
                    font-family: 'NHaas-55Rg';
                    font-size: 16px;
                    color: #efffe3;

                    &.name {
                        font-family: 'NHaas-65Md';
                        font-weight: bold;
                    }
                }
            }
        }

        .section-steps {
            position: relative;
            min-height: 720px;
            padding: 140px 40px;

            @media(max-width: 992px) {
                padding: 60px 40px;
            }

            @media(min-width: 1150px) {
                padding: 150px 60px 170px;
            }

            @media(min-width: 1350px) {
                padding: 150px 60px 220px;
            }

            .scheme-wrapper {
                position: relative;

                @media(max-width: 992px) {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                }

                .line {
                    width: calc(100% + 120px);
                    margin-left: -60px;

                    @media(max-width: 992px) {
                        display: none;
                    }
                }

                .circle {
                    width: 44px;
                    height: 44px;
                    border: 2px solid #3be089;
                    border-radius: 50%;
                    overflow: hidden;
                    position: absolute;
                    background: #efffe3;

                    @media(max-width: 992px) {
                        display: none;
                    }

                    &.middle {
                        @media(max-width: 1300px) {
                            left: 45%!important;
                            top: calc(75% - 5px)!important;
                        }
                    }
                }
            }

            .step-list-wrapper {
                @media(max-width: 992px) {
                    display: flex;
                    flex-wrap: wrap;
                    align-items: center;
                    gap: 30px;
                }

                .step-wrapper {
                    position: absolute;
                    width: 240px;
                    display: flex;
                    flex-direction: column;
                    gap: 20px;

                    @media(max-width: 992px) {
                        position: relative;
                        top: unset!important;
                        left: unset!important;
                        width: calc(50% - 20px);
                    }

                    @media(max-width: 768px) {
                        width: 100%;
                    }

                    .name {
                        color: #3be089;
                        font-size: 21px;
                        font-weight: 700;
                        font-family: 'NHaas-55Rg';
                        text-transform: uppercase;
                    }

                    .description {
                        color: #004850;
                        font-size: 17px;
                        line-height: 1.5;
                        font-family: 'NHaas-55Rg';
                    }
                }
            }

            .title-wrapper {
                display: flex;
                flex-direction: column;
                gap: 40px;
                position: absolute;
                top: -100px;

                @media(max-width: 1400px) {
                    top: -100px;
                }

                @media(max-width: 1200px) {
                    top: -100px;
                }

                @media(max-width: 992px) {
                    position: relative;
                    top: unset!important;
                    left: unset!important;
                    margin-bottom: 70px;
                }

                .title {
                    font-family: 'Spezia-Serif-C';
                    font-size: 41px;
                    line-height: 1.2;
                    color: #004850;

                    @media(max-width: 992px) {
                        font-size: 34px;
                        text-align: center;
                    }

                    @media(max-width: 768px) {
                        text-align: left;
                    }
                }

                .details {
                    max-width: 490px;
                    font-family: 'NHaas-55Rg';
                    font-size: 21px;
                    color: #004850;
                    line-height: 1.35;
                }
            }
        }

        .section-tool {
            display: flex;
            flex-direction: column;
            align-items: center;
            padding: 80px 60px 80px;
            background-color: #004850;
            gap: 60px;

            @media(max-width: 992px) {
                padding: 90px 40px 70px;
            }

            .title {
                font-family: 'Spezia-Serif-C';
                font-size: 48px;
                line-height: 1.2;
                color: #efffe3;

                @media(max-width: 992px) {
                    font-size: 34px;
                    text-align: center;
                }

                @media(max-width: 768px) {
                    text-align: left;
                }
            }

            .section-list {
                display: flex;
                flex-wrap: wrap;
                justify-content: center;
                gap: 25px;

                .section-list-item {
                    flex-shrink: 0;
                    display: flex;
                    flex-wrap: wrap;
                    flex-direction: column;
                    align-items: center;
                    gap: 20px;
                    width: calc(25% - 20px);
                    min-width: 305px;

                    .image {
                        width: 36px;
                        height: 40px;
                    }

                    .name {
                        font-size: 24px;
                        font-weight: 700;
                        color: #3be089;
                        text-align: center;
                    }

                    .description {
                        font-size: 16px;
                        line-height: 1.5;
                        text-align: center;
                        font-family: 'NHaas-55Rg';
                        color: #efffe3;

                        @media(max-width: 992px) {
                            text-align: left;
                        }
                    }
                }
            }
        }

        .section-contacts {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 80px 60px 0;

            @media(max-width: 992px) {
                flex-direction: column;
                padding: 70px 40px 0;
                gap: 30px;
            }

            .info-wrapper {
                display: flex;
                flex-direction: column;
                gap: 30px;
            }

            .title {
                font-family: 'Spezia-Serif-C';
                font-size: 41px;
                line-height: 1.2;
                color: #004850;
                max-width: 50%;

                @media(max-width: 992px) {
                    font-size: 34px;
                    text-align: center;
                    max-width: unset;
                }
            }

            .details {
                font-family: 'NHaas-55Rg';
                font-size: 18px;
                line-height: 1.5;
                color: #004850;
            }

            .btn {
                background: #004850;
                border: 0;
                padding: 12px 24px;
                border-radius: 0;
                font-size: 16px;
                font-family: 'NHaas-65Md';
                color: #e4ffd1;
            }
        }
    }
}
