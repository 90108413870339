.page-faq {
    display: flex;
    flex-direction: column;
    width: 100%;

    .main-bg-image {
        width: 100%;
        height: 634px;
        object-fit: cover;

        @media(max-width: 575px) {
            height: 400px;
        }

        @media(max-width: 1200px) {
            height: 500px;
        }
    }

    .summary {
        width: 100%;
        display: flex;
        background: #004850;
        padding: 80px 75px;
        gap: 160px;

        @media(max-width: 1200px) {
            gap: 80px;
        }

        @media(max-width: 992px) {
            flex-direction: column;
            align-items: center;
            padding: 100px 40px;
            gap: 50px;
        }

        .title {
            flex-shrink: 0;
            width: 50%;
            min-width: 520px;
            font-family: 'Spezia-Serif-C';
            font-size: 50px;
            line-height: 1.2;
            color: #efffe3;

            @media(max-width: 1200px) {
                font-size: 40px;
                min-width: 400px;
            }

            @media(max-width: 992px) {
                font-size: 34px;
                min-width: unset;
                width: 100%;
                text-align: center
            }

            @media(max-width: 768px) {
                font-size: 34px;
                min-width: 300px;
            }
        }

        .details {
            max-width: 568px;
            font-family: 'NHaas-55Rg';
            font-size: 21px;
            color: #efffe3;
            margin-top: 10px;

            @media(max-width: 992px) {
                font-size: 16px;
                margin-top: 0;
                max-width: unset;
            }
        }
    }

    .content-wrapper {
        display: flex;
        flex-direction: column;
        align-items: center;
        background: #efffe3;
        padding-top: 57px;
        padding-bottom: 80px;

        @media(max-width: 992px) {
            padding-bottom: 87px;
        }

        .section-list {
            width: 100%;
            display: flex;
            flex-direction: column;
            gap: 30px;
            padding: 0 60px;
            max-width: 1017px;

            @media(max-width: 992px) {
                padding: 0 40px;
            }

            .title {
                font-family: 'Spezia-Serif-C';
                font-size: 41px;
                line-height: 1.2;
                color: #004850;
                margin-bottom: 15px;
                text-align: left;
                margin-top: 50px;

                @media(max-width: 992px) {
                    margin-top: 20px;
                }
            }

            .section-list-item {
                display: flex;
                flex-direction: column;
                gap: 20px;

                .question {
                    cursor: pointer;
                    position: relative;
                    width: 100%;
                    background: #e5fbd6;
                    padding: 20px 40px 20px 20px;
                    font-family: 'NHaas-55Rg';
                    font-size: 21px;
                    line-height: 1.35;

                    &::before {
                        content: '';
                        position: absolute;
                        background-image: url('../../images/faq/toggle.svg');
                        width: 28px;
                        height: 17px;
                        top: 50%;
                        left: calc(100% - 45px);
                        transform: translateY(-50%);
                    }

                    @media(max-width: 992px) {
                        font-size: 18px;
                        padding: 20px 50px 20px 20px;
                    }
                }

                .answer {
                    display: none;
                    width: 100%;
                    background: #004850;
                    padding: 15px 20px;
                    font-family: 'NHaas-55Rg';
                    font-size: 21px;
                    line-height: 1.35;
                    color: #efffe3;
                    transition: 0.1s display;

                    @media(max-width: 992px) {
                        font-size: 17px;
                    }
                }

                &.open {
                    .answer {
                        display: block;
                    }
                }
            }
        }

        .section-contact {
            display: flex;
            flex-direction: column;
            width: 100%;
            max-width: 616px;
            margin-top: 90px;

            @media(max-width: 992px) {
                padding: 0 40px;
                margin-top: 60px;
            }

            .title {
                font-family: 'Spezia-Serif-C';
                font-size: 48px;
                line-height: 1.2;
                color: #004850;
                margin-bottom: 30px;

                @media(max-width: 992px) {
                    font-size: 34px;
                    text-align: center;
                    max-width: unset;
                }

                @media(max-width: 768px) {
                    text-align: left;
                }
            }

            .details {
                font-family: 'NHaas-55Rg';
                font-size: 18px;
                color: #004850;

                @media(max-width: 992px) {
                    text-align: center;
                    max-width: unset;
                }

                @media(max-width: 768px) {
                    text-align: left;
                }
            }

            .btn {
                width: 140px;
                padding: 12px 24px;
                border-radius: 0;
                font-size: 16px;
                font-family: 'NHaas-65Md';
                margin-top: 30px;
                align-self: center;
            }
        }
    }
}