.page-marketplace {
    display: flex;
    flex-direction: column;

    .main-bg-image {
        width: 100%;
        height: 634px;
        object-fit: cover;

        @media(max-width: 575px) {
            height: 400px;
        }

        @media(max-width: 1200px) {
            height: 500px;
        }
    }

    .summary {
        width: 100%;
        display: flex;
        background: #acb0ff;
        padding: 80px 75px 80px;
        gap: 160px;

        @media(max-width: 1200px) {
            gap: 80px;
        }

        @media(max-width: 992px) {
            flex-direction: column;
            align-items: center;
            padding: 60px 40px;
            gap: 50px;
        }

        .summary-title-wrapper {
            display: flex;
            gap: 60px;
            width: 60%;

            @media(max-width: 992px) {
                flex-direction: column;
                align-items: center;
            }
        }

        .summary-image {
            width: 135px;
            height: 135px;
        }

        .title {
            font-family: 'Spezia-Serif-C';
            font-size: 50px;
            line-height: 1.2;
            color: #004850;
            margin-top: 10px;

            @media(max-width: 1200px) {
                font-size: 40px;
                max-width: 350px;
            }

            @media(max-width: 992px) {
                font-size: 34px;
                min-width: unset;
                width: 100%;
                text-align: left;
            }

            @media(max-width: 768px) {
                font-size: 34px;
                min-width: 300px;
            }
        }

        .details {
            max-width: 568px;
            font-family: 'NHaas-55Rg';
            font-size: 21px;
            color: #004850;
            margin-top: 40px;

            @media(max-width: 1200px) {
                margin-top: 0;
            }

            @media(max-width: 992px) {
                font-size: 16px;
                margin-top: 0;
                max-width: unset;
                text-align: left;
            }
        }
    }

    .content-wrapper {
        background: #efffe3;

        @media(max-width: 992px) {
            padding-bottom: 0px;
        }

        .section-principles {
            @media(max-width: 992px) {
                margin-bottom: 0!important;
                padding-bottom: 20px;

                .section-text-wrapper {
                    margin-top: 0!important;
                }
            }
        }

        .section-with-image {
            display: flex;
            align-items: center;
            margin-bottom: 120px;

            @media(max-width: 992px) {
                flex-direction: column-reverse !important;
                margin-bottom: 60px;
                gap: 60px;
            }

            &.reverse {
                flex-direction: row-reverse;
            }

            .section-text-wrapper {
                flex: 1;
                display: flex;
                flex-direction: column;
                gap: 45px;
                padding: 0 60px;

                @media(max-width: 992px) {
                    padding: 0 40px;
                }

                .title {
                    font-family: 'Spezia-Serif-C';
                    font-size: 41px;
                    line-height: 1.2;
                    color: #004850;

                    @media(max-width: 992px) {
                        font-size: 34px;
                        text-align: center;
                    }

                    @media(max-width: 768px) {
                        text-align: left;
                    }
                }

                .details {
                    font-family: 'NHaas-55Rg';
                    font-size: 21px;
                    color: #004850;

                    @media(max-width: 992px) {
                        font-size: 15px;
                        text-align: left;
                    }
                }

                .point-list {
                    margin-top: -20px;
                    padding-left: 0px;
                    list-style-type: none;
                    display: flex;
                    flex-direction: column;
                    gap: 20px;

                    li {
                        position: relative;
                        display: flex;
                        align-items: center;
                        height: 50px;
                        font-family: 'NHaas-55Rg';
                        font-size: 17px;
                        color: #004850;
                        padding-left: 30px;

                        &:before {
                            position: absolute;
                            content: '';
                            display: inline-block;
                            height: 20px;
                            width: 20px;
                            background-image: url("../../images/marketplace/list-image.svg");
                            background-size: contain;
                            background-repeat: no-repeat;
                            left: 0;
                          }
                    }
                }
            }

            .section-image {
                flex: 1;
                width: 50%;
                height: 704px;
                object-fit: cover;

                @media(max-width: 992px) {
                    width: 100%;
                    height: 450px;
                    flex: unset;
                }
            }

            &.small {
                .section-text-wrapper {
                    padding: 0 30px;

                    @media(max-width: 992px) {
                        padding: 0 40px;
                    }
                }

                .title {
                    font-size: 29px;
                }
            }
        }

        .section-market-principles {
            display: flex;
            margin-bottom: 80px;
            margin-top: -40px;

            @media(max-width: 992px) {
                flex-direction: column !important;
                margin-bottom: 80px;
                gap: 30px;
            }

            .section-text-wrapper {
                flex: 1;
                display: flex;
                flex-direction: column;
                gap: 45px;
                padding: 0 60px;
                margin-top: 80px;

                @media(max-width: 992px) {
                    padding: 0 40px;
                    margin-top: 20px;
                    gap: 15px;
                }

                .title {
                    font-family: 'Spezia-Serif-C';
                    font-size: 41px;
                    line-height: 1.2;
                    color: #004850;

                    @media(max-width: 992px) {
                        font-size: 34px;
                        text-align: center;
                    }
                }

                .details {
                    font-family: 'NHaas-55Rg';
                    font-size: 17px;
                    color: #004850;
                    margin-top: 30px;
                }
            }

            .point-list {
                width: 50%;
                display: flex;
                flex-wrap: wrap;
                align-items: center;
                gap: 100px 30px;

                @media(max-width: 992px) {
                    width: 100%;
                    padding: 0 40px;
                    gap: 60px 30px;
                }

                .point-list-item {
                    display: flex;
                    flex-direction: column;
                    gap: 20px;
                    width: calc(50% - 25px);

                    @media(max-width: 768px) {
                        width: 100%;
                     }

                    .title {
                        font-family: 'NHaas-65Md';
                        font-size: 21px;
                        color: #acb0ff;
                        font-weight: bold;
                        text-align: center;
    
                        @media(max-width: 992px) {
                            text-align: center;
                        }    
                    }
    
                    .details {
                        text-align: left;
                        font-family: 'NHaas-55Rg';
                        font-size: 17px;
                        color: #004850;
                    }
                }
            }
        }

        .section-conversation {
            width: 100%;
            display: flex;
            padding: 60px 75px 60px;

            @media(max-width: 992px) {
                flex-direction: column;
                gap: 40px;
                padding: 30px 40px;
            }

            .section-text-wrapper {
                flex: 1;
                display: flex;
                flex-direction: column;
                gap: 45px;
                padding: 0;

                @media(max-width: 992px) {
                    width: 100%;
                    padding: 0;
                }

                .title {
                    font-family: 'Spezia-Serif-C';
                    font-size: 34px;
                    line-height: 1.2;
                    color: #004850;

                    @media(max-width: 992px) {
                        font-size: 34px;
                        text-align: center;
                    }

                    @media(max-width: 768px) {
                        text-align: left;
                    }
                }

                .details {
                    font-family: 'NHaas-55Rg';
                    font-size: 17px;
                    color: #004850;
                    max-width: 556px;
                }
            }

            .btn-container {
                width: 50%;
                display: flex;
                justify-content: center;
                align-items: center;
                
                @media(max-width: 992px) {
                    width: 100%;
                }

                .btn {
                    width: 285px;
                    padding: 12px 24px;
                    border-radius: 0;
                    font-size: 16px;
                    font-family: 'NHaas-65Md';
                }
            }
        }

        .section-quotation {
            width: 100%;
            padding: 50px 60px 80px;
            display: flex;
            flex-direction: column;
            gap: 15px;
            align-items: center;
            background: #e5fbd6;

            @media(max-width: 992px) {
                padding: 50px 40px 60px;
            }

            .avatar {
                width: 116px;
                height: 116px;
                border-radius: 50%;
                overflow: hidden;
                margin-bottom: 20px;
                object-fit: cover;
            }

            .details {
                max-width: 980px;
                font-family: 'NHaas-55Rg';
                font-size: 21px;
                color: #004850;
                text-align: left;

                @media(max-width: 992px) {
                    font-size: 15px;
                }
            }

            .title-wrapper {
                display: flex;
                flex-direction: column;
                align-items: center;
                font-family: 'NHaas-55Rg';
                margin-bottom: 20px;

                .ceo-name {
                    font-size: 21px;
                    color: #acb0ff;
                    font-weight: bold;
                }

                .position {
                    font-size: 17px;
                    color: #004850;
                }
            }
        }
    }
}