.page-about-us {
    display: flex;
    flex-direction: column;
    width: 100%;

    .main-bg-image {
        width: 100%;
        height: 634px;
        object-fit: cover;

        @media(max-width: 575px) {
            height: 400px;
        }

        @media(max-width: 1200px) {
            height: 500px;
        }
    }

    .summary {
        position: relative;
        width: 100%;
        display: flex;
        background: #004850;
        padding: 190px 75px;
        gap: 80px;

        @media(max-width: 1200px) {
            gap: 80px;
        }

        @media(max-width: 992px) {
            flex-direction: column;
            align-items: center;
            padding: 100px 40px;
            gap: 50px;
        }

        .title {
            width: 50%;
            flex-shrink: 0;
            font-family: 'Spezia-Serif-C';
            font-size: 50px;
            line-height: 1.2;
            color: #efffe3;
            z-index: 1;

            @media(max-width: 1200px) {
                font-size: 40px;
                min-width: 400px;
            }

            @media(max-width: 992px) {
                font-size: 34px;
                min-width: unset;
                width: 100%;
            }

            @media(max-width: 768px) {
                font-size: 34px;
                min-width: 300px;
            }
        }

        .details {
            max-width: 750px;
            font-family: 'NHaas-55Rg';
            font-size: 21px;
            color: #efffe3;
            margin-top: 10px;
            z-index: 1;

            @media(max-width: 992px) {
                font-size: 16px;
                margin-top: 0;
                max-width: unset;
            }
        }

        .flower-bg {
            position: absolute;
            bottom: 0;
            left: 50%;
            transform: translateX(-50%);
            width: 100%;
        }
    }

    .content-wrapper {
        background: #efffe3;

        .section-with-image {
            display: flex;
            align-items: center;

            @media(max-width: 992px) {
                flex-direction: column-reverse !important;
                gap: 100px;
            }

            &.reverse {
                flex-direction: row-reverse;
            }

            .section-text-wrapper {
                flex: 1;
                display: flex;
                flex-direction: column;
                gap: 45px;
                padding: 0 60px;

                @media(max-width: 992px) {
                    padding: 0 40px;
                }

                .title {
                    font-family: 'Spezia-Serif-C';
                    font-size: 41px;
                    line-height: 1.2;
                    color: #004850;

                    @media(max-width: 992px) {
                        font-size: 34px;
                        text-align: center;
                    }
                }

                .details {
                    font-family: 'NHaas-55Rg';
                    font-size: 21px;
                    color: #004850;

                    @media(max-width: 992px) {
                        font-size: 15px;
                    }
                }
            }

            .section-image {
                flex: 1;
                width: 50%;
                object-fit: cover;

                @media(max-width: 992px) {
                    width: 100%;
                    height: 450px;
                    flex: unset;
                }
            }
        }

        .section-ceo {
            width: 100%;
            height: 515px;
            background-image: url('../../images/about-us/bg-lines.png');
            background-size: cover;
            background-position: center;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            padding: 20px;
            text-align: center;

            @media(max-width: 992px) {
                padding: 50px 40px;
                height: unset;
            }

            .lotus-logo {
                margin-bottom: 70px;
            }

            .details {
                max-width: 980px;
                font-family: 'NHaas-55Rg';
                font-size: 21px;
                color: #efffe3;
                text-align: left;
                font-style: italic;
            }

            .title-wrapper {
                display: flex;
                flex-direction: column;
                align-items: center;
                font-family: 'NHaas-55Rg';
                margin-top: 50px;

                .ceo-name {
                    font-size: 21px;
                    color: #3be088;
                    font-weight: bold;
                    font-family: 'NHaas-55Rg';
                }

                .position {
                    font-size: 17px;
                    color: #efffe3;
                    font-family: 'NHaas-55Rg';
                }
            }
        }

        .section-story {
            position: relative;
            display: flex;
            flex-direction: column;
            align-items: center;
            padding: 80px 60px 0;

            .title {
                font-family: 'Spezia-Serif-C';
                font-size: 46px;
                line-height: 1.2;
                color: #004850;

                @media(max-width: 992px) {
                    text-align: center;
                }
            }

            .timeline-wrapper {
                position: relative;

                @media(max-width: 992px) {
                    margin-bottom: 60px;
                }

                .timeline-image {
                    width: calc(100% + 120px);
                    margin-left: -60px;

                    @media(max-width: 992px) {
                        display: none;
                    }
                }

                .timeline-item-wrapper {
                    position: relative;
                    display: flex;
                    justify-content: space-between;
                    width: 100%;
                    padding-top: 40px;
                    height: 230px;
                    top: -60px;

                    @media(min-width: 1500px) {
                        height: 180px;
                    }

                    @media(min-width: 1300px) {
                        height: 230px;
                    }

                    @media(min-width: 1101px) {
                        height: 330px;
                    }

                    @media(max-width: 1100px) {
                        height: 350px;
                    }

                    @media(max-width: 992px) {
                        flex-direction: column;
                        gap: 30px;
                        height: unset;
                        top: unset;
                    }

                    .timeline-item {
                        position: absolute;
                        display: flex;
                        flex-direction: column;
                        gap: 15px;
                        align-items: center;
                        width: 25%;
                        transform: translateX(-50%);

                        @media(max-width: 992px) {
                            position: static;
                            width: 100%;
                            transform: unset;
                        }

                        &:nth-child(1) {
                            left: 20%;
                        }

                        &:nth-child(2) {
                            left: 50%;
                        }

                        &:nth-child(3) {
                            left: 80%;
                        }

                        .title {
                            color: #3BE089;
                            font-size: 17px;
                            font-weight: bold;
                            font-family: 'NHaas-55Rg';
                        }

                        .description {
                            color: #004850;
                            font-size: 17px;
                            line-height: 1.5;
                            font-family: 'NHaas-55Rg';
                        }
                    }
                }
            }
        }

        .section-advisors {
            display: flex;
            flex-direction: column;

            .title {
                font-family: 'Spezia-Serif-C';
                font-size: 41px;
                line-height: 1.2;
                color: #004850;
                margin: 0px 60px 70px;
                text-align: center;
    
                @media(max-width: 1200px) {
                    font-size: 34px;
                    margin: 0px 40px 50px;
                }
            }

            .advisor-list {
                display: flex;
                flex-wrap: wrap;
                border-width: 2px 0px;
                border: 2px solid #e5fbd6;

                @media(max-width: 992px) {
                    flex-direction: column;
                    border: 0!important;
                }

                .advisor-wrapper {
                    width: 50%;
                    padding: 40px 60px;
                    display: flex;
                    gap: 40px;

                    @media(max-width: 992px) {
                        width: 100%;
                        border: 0!important;
                        flex-direction: column;
                        align-items: center;
                        gap: 20px;
                        padding: 40px;
                    }

                    &:nth-child(odd) {
                        border-width: 0px 2px 2px 0;
                        border-color: #e5fbd6;
                        border-style: solid;
                    }

                    &:nth-child(even) {
                        border-width: 0px 0px 2px 0px;
                        border-color: #e5fbd6;
                        border-style: solid;
                    }

                    .advisor-avatar {
                        width: 116px;
                        height: 116px;
                        border-radius: 50%;
                        overflow: hidden;
                        object-fit: cover;
                        flex-shrink: 0;

                        @media(max-width: 992px) {
                            width: 100px;
                            height: 100px;
                        }
                    }

                    .advisor-info-wrapper{
                        display: flex;
                        flex-direction: column;
                        gap: 20px;
                        margin-top: 20px;

                        @media(max-width: 992px) {
                            margin-top: 0;
                        }

                        .name-wrapper {
                            display: flex;
                            flex-direction: column-reverse;
                            justify-content: center;
                            align-items: center;

                            .name {
                                font-size: 21px;
                                font-weight: 700;
                                color: #3be089;
                                font-family: 'NHaas-55Rg';
    
                                @media(max-width: 992px) {
                                    text-align: center;
                                }
                            }
                        }

                        .position {
                            font-size: 16px;
                            color: #004850;
                            font-family: 'NHaas-55Rg';
                            margin-bottom: 15px;
                        }

                        .description {
                            font-size: 16px;
                            color: #004850;
                            font-family: 'NHaas-55Rg';
                        }
                    }
                }
            }
        }

        .section-leaders {
            display: flex;
            flex-direction: column;
            padding: 80px 60px;

            @media(max-width: 992px) {
                padding: 70px 40px;
            }

            .title {
                font-family: 'Spezia-Serif-C';
                font-size: 41px;
                line-height: 1.2;
                color: #004850;
                margin: 0 60px 70px;
                text-align: center;
    
                @media(max-width: 992px) {
                    font-size: 34px;
                    margin: 0 0 50px;
                }
            }

            .leader-list {
                display: flex;
                gap: 70px;
                
                @media(max-width: 1200px) {
                    flex-direction: column;
                }

                .leader-item {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    gap: 30px;

                    .comment {
                        font-size: 20px;
                        line-height: 1.4;
                        color: #004850;
                        text-align: center;
                        font-family: 'NHaas-55Rg';
                        font-style: italic;
                        text-align: left;
                    }

                    .leader-avatar {
                        width: 116px;
                        height: 116px;
                        border-radius: 50%;
                        overflow: hidden;
                        object-fit: cover;
                        flex-shrink: 0;
                        object-position: top;

                        @media(max-width: 992px) {
                            width: 100px;
                            height: 100px;
                        }
                    }

                    .leader-info-wrapper {
                        display: flex;
                        flex-direction: column;
                        align-items: center;

                        .name {
                            font-size: 16px;
                            font-family: 'NHaas-65Md';
                        }

                        .position {
                            font-size: 15px;
                            font-family: 'NHaas-55Rg';
                        }
                    }
                }
            }
        }

        .section-team {
            display: flex;
            flex-direction: column;
            padding: 30px 60px 80px;

            @media(max-width: 992px) {
                padding: 30px 40px 80px;
            }

            .title {
                font-family: 'Spezia-Serif-C';
                font-size: 41px;
                line-height: 1.2;
                color: #004850;
                margin: 0 60px 70px;
                text-align: center;
    
                @media(max-width: 992px) {
                    font-size: 34px;
                    margin: 0 0 70px;
                }
            }

            .member-list {
                display: flex;
                justify-content: center;
                gap: 50px;
                flex-wrap: wrap;

                .member-item {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    gap: 30px;
                    width: calc(33% - 40px);
                    flex-shrink: 0;

                    @media(max-width: 992px) {
                        width: calc(50% - 30px);
                    }

                    .member-avatar {
                        width: 116px;
                        height: 116px;
                        border-radius: 50%;
                        overflow: hidden;
                        object-fit: cover;
                        flex-shrink: 0;
                        object-position: top;

                        &.centered {
                            object-position: center;
                        }

                        @media(max-width: 992px) {
                            width: 100px;
                            height: 100px;
                        }
                    }

                    .member-info-wrapper {
                        display: flex;
                        flex-direction: column;
                        align-items: center;

                        .name {
                            font-size: 16px;
                            font-family: 'NHaas-65Md';
                        }

                        .position {
                            font-size: 15px;
                            font-family: 'NHaas-55Rg';
                            text-align: center;
                        }
                    }
                }
            }
        }

        .section-contact {
            display: flex;
            flex-direction: column;
            align-items: center;
            padding: 80px 60px;
            background: #e5fbd6;

            @media(max-width: 992px) {
                padding: 60px 40px;
            }

            .title {
                font-family: 'Spezia-Serif-C';
                font-size: 41px;
                line-height: 1.2;
                color: #004850;
                margin: 0 60px 70px;
                text-align: center;
    
                @media(max-width: 992px) {
                    width: 100%;
                    font-size: 34px;
                    margin: 0 0 70px;
                    text-align: left;
                }
            }

            .details {
                font-size: 21px;
                line-height: 1.35;
                color: #004850;
                font-family: 'NHaas-55Rg';
                text-align: center;
                max-width: 700px;

                @media(max-width: 992px) {
                    text-align: left;
                }
            }

            .product-link {
                font-size: 12px;
                color: #004850;
                padding-bottom: 15px;
                border-bottom: 2px solid #004850;
                margin-top: 50px;
                text-decoration: none;
                font-weight: bold;
                font-family: 'NHaas-65Md';
                text-transform: uppercase;
                width: fit-content;
              
                img {
                  margin-left: 8px;
                }
              }
        }
    }
}
