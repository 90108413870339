.footer {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 250px;
    background-color: #004850;
    padding: 0 48px;

    .content-wrapper {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        height: 250px;

        @media(max-width: 992px) {
            flex-direction: column;
            gap: 30px;
            height: unset;
            padding: 30px 0;
            align-items: center;
            justify-content: flex-end;
        }

        .logo {
            width: 168px;
        }

        .navbar {
            display: flex;
            gap: 15px;
            margin-left: 55px;

            @media(max-width: 992px) {
                margin-left: 0;
                justify-content: center;
            }

            a {
                text-decoration: none;

                &:hover {
                    .navbar-item {
                        color: #3be089;
                    }
                }
            }

            .navbar-item {
                font-family: "NHaasGrotesk";
                margin-left: 10px;
                color: #e4ffd1;
            }
        }

        .social-network-wrapper {
            display: flex;
            gap: 25px;
            margin-left: 55px;

            @media(max-width: 992px) {
                margin-left: 0;
            }

            img {
                &:hover {
                    scale: 1.3;
                    transition: 100ms scale;
                }
            }
        }
    }
}