.page-layout {
    display: flex;
    flex-direction: column;
    width: 100%;
    min-height: 100vh;

    > *:not(.page-content) {
        flex: 0;
    }

    .page-content {
        flex: 1;
        display: flex;
        justify-content: center;
        background: #004850;
    }
}