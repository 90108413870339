.outer-box {
    background-color: #004850;
    display: flex;
    flex-direction: column;
    align-items: center;
    height: auto;
  }

  .products-section-1 {
    width: 100%;
    height: 100%;
    flex-direction: row;
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }

  .products-section-1-photo {
    width: 100%;
    height: 100%;
  }
  
  .products-section-2 {
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100%;
    gap: 5%;
    padding: 50px 40px;
  }

  .products-section-2-left {
    width: 50%;
    display: flex;
    height: 550px;
    justify-content: center;
    flex-direction: column;
  }

  .photo {
    width: 100%;
    height: 100%;
    object-fit: 'cover';
  }
  
  .products-section-2-left-text {
    color: #e4ffd1;
    font-size: 49px;
    font-family: Spezia-Serif-C;
    height: fit-content;
    text-align: right;
    display: flesolid;
  }
  
  .products-section-2-right {
    width: 100%;
    max-width: 900px;
    color: #e4ffd1;
    font-size: 21px;
    text-align: left;
    font-family: NHaas-55Rg;
    display: flex;
    justify-content: center;
    flex-direction: column;
    padding: 60px 60px;
  }

  .products-section-2-right-inner-text {
    width: 100%;
    color: #e4ffd1;
    font-size: 20px;
    padding: 0%;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
  }
  
  .inner-text {
    width: 70%;
  }

  .products-section-3 {
    background-color: #3be089;
    display: flex;
    height: 761px;
  }
  
  .products-section-3-left {
    width: 50%;
    display: flex;
    flex-direction: column;
  }

  .products-section-3-left-container {
    padding: 10%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .left-container {
    width: 50%;
    display: flex;
    flex-direction: column;
  }

  .left-side-content {
    padding: 10%;
  }

  .leaf-image {
    width: 82px;
    height: 82px;
    margin-top: 10%;
  }

  .products-section-3-header {
    color: #004850;
    font-family: 'Spezia-Serif-C';
    font-size: 40px;
    margin-top: 10%;
    text-align: center;
  }
  
  .products-section-3-text {
    font-size: 21px;
    color: #004850;
    margin-top: 10%;
    font-family: 'NHaas-55Rg';
    text-align: center;
  }

  .products-section-3-right {
    width: 50%;
  }
  
  .products-section-4 {
    background-color: #eec833;
    display: flex;
    flex-direction: row-reverse;
    height: 761px;
  }

  .products-section-4-right {
    width: 50%;
    display: flex;
    flex-direction: column;
    padding: 0 40px;
  }

  .products-section-4-right-content {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .products-section-4-right-header {
    color: #004850;
    font-family: 'Spezia-Serif-C';
    font-size: 40px;
    margin-top: 60px;
    text-align: center;
  }
  
  .products-section-4-right-text {
    font-size: 21px;
    color: #004850;
    margin-top: 60px;
    font-family: 'NHaas-55Rg';
    text-align: center;
  }
  
  .products-section-4-left {
    width: 50%;
  }

  .products-section-5 {
    background-color: #acb0ff;
    display: flex;
    flex-direction: row;
    height: 761px;
  }
  
  .products-section-5-right {
    width: 50%;
    display: flex;
    flex-direction: column;
    padding: 0 40px 10%;
  }

  .products-section-5-right-content {
    padding: 10%;
  }

  .products-section-5-right-header {
    color: #004850;
    font-family: 'Spezia-Serif-C';
    font-size: 40px;
    text-align: center;
    margin-top: 10%;
  }
  
  .products-section-5-left {
    width: 50%;
  }
  
  .products-section-5-right-text {
    font-size: 21px;
    color: #004850;
    margin-top: 10%;
    font-family: 'NHaas-55Rg';
    text-align: center;
  }
  
  .products-section-5-photo {
    width: 100%;
    height: 100%;
  }
  
  .left-side-content {
    padding: 10%;
  }
  
  .products-section-6 {
    width: 100%;
    background-color: #efffe3;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .products-section-6-content {
    width: 40%;
    display: flex;
    flex-direction: column;
    justify-content: center;
  
    height: 370px;
  }
  
  .products-section-6-header {
    color: #004850;
    font-family: 'Spezia-Serif-C';
    font-size: 40px;
    margin-top: 30px;
    text-align: center;
  }
  
  .products-section-6-text {
    font-size: 21px;
    color: #004850;
    font-family: 'NHaas-55Rg';
    text-align: center;
    width: 70%;
    align-self: center;
    margin-top: 30px;
  }
  
  @media (max-width: 800px) {
    .products-section-1 {
      width: 100%;
      height: 400px;
      flex-direction: row;
      display: flex;
      align-items: center;
      justify-content: flex-end;
    }

    .products-section-1-photo {
      width: 100%;
      height: 100%;
    }

    .content {
      width: 100%;
      height: 395px;
      flex-direction: row;
      display: flex;
      align-items: center;
      justify-content: flex-end;
    }

    .products-section-2 {
      flex-direction: column;
      width: 100%;
      height: unset;
      justify-content: center;
      padding: 50px 40px;
      gap: 35px;
    }

    .products-section-2-left {
      width: 100%;
      display: flex;
      height: 100%;
      padding-top: 0;
      justify-content: flex-end;
      flex-direction: column;
      height: unset;
    }
  
    .products-section-2-left-text {
      color: #e4ffd1;
      font-size: 30px;
      font-family: Spezia-Serif-C;
      height: fit-content;
      width: 100%;
      text-align: center;
    }
  
    .products-section-2-right {
      width: 100%;
      text-align: center;
      font-size: 18px;
      margin-top: 0%;
      font-family: NHaas-55Rg;
      justify-content: flex-start;
      height: unset;
      padding: 0;
    }
  
    .products-section-2-right-inner-text {
      width: 100%;
      color: #e4ffd1;
      font-size: 21px;
      text-align: left;
      height: unset;
      padding: 0;
      display: flex;
      flex-direction: column;
      justify-content: center;
    }

    .products-section-3 {
      background-color: #3be089;
      display: flex;
      width: 100%;
      flex-direction: column-reverse;
      height: auto;
    }

    .products-section-3-left {
      width: 100%;
      justify-content: 'center';
      display: 'flex';
      flex-direction: column;
    }

    .products-section-3-left-container {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 50px 40px;
    }

    .products-section-3-right {
      width: 100%;
    }
  
    .products-section-4 {
      background-color: #eec833;
      display: flex;
      flex-direction: column-reverse;
      height: auto;
    }

    .products-section-4-right {
      width: 100%;
      justify-content: 'center';
      display: 'flex';
      flex-direction: column;
      align-items: center;
    }
  
    .products-section-4-right-content {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 50px 0;
    }

    .products-section-4-left-content {
      padding: 10%;
    }

    .products-section-4-left {
      width: 100%;
    }
  
    .products-section-5 {
      background-color: #acb0ff;
      display: flex;
      flex-direction: column;
      height: auto;
    }

    .products-section-5-right {
      width: 100%;
      display: flex;
      flex-direction: column;
    }

    .products-section-5-right-header {
      color: #004850;
      font-family: 'Spezia-Serif-C';
      font-size: 40px;
      margin: 0;
      text-align: center;
      padding: 50px 0px;
    }

    .products-section-5-right-text {
      font-size: 21px;
      color: #004850;
      margin-top: 0;
      font-family: 'NHaas-55Rg';
      text-align: center;
    }

    .products-section-5-left {
      width: 100%;
    }

    .products-section-6 {
      width: 100%;
      background-color: #efffe3;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 70px 40px;
    }
  
    .products-section-6-content {
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      height: unset;
      gap: 25px;
    }
  
    .products-section-6-header {
      color: #004850;
      font-family: 'Spezia-Serif-C';
      font-size: 40px;
      margin-top: 0%;
      text-align: left;
    }
  
    .products-section-6-text {
      font-size: 21px;
      color: #004850;
      font-family: 'NHaas-55Rg';
      text-align: center;
      width: 100%;
      text-align: left;
    }

    .left-container {
      width: 100%;
      justify-content: 'center';
      display: 'flex';
      flex-direction: column;
    }

    .left-side-content {
      padding: 10%;
    }

    .full-width-content-outer-box {
      width: 100%;
      height: 426px;
      background-color: #efffe3;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    
    .full-width-content {
      width: 90%;
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
  }
  